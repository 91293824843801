import * as React from 'react'
import { IconAffirm } from '../IconsCheckout'
import IconExtLink from '../IconExtLink'

const AffirmFinancing = () => {
  return (
    <div className="flex flex-col md:flex-row w-full gap-3">
      <div className="w-full max-w-[350px] ">
        <IconAffirm className="w-full h-full text-black p-5" />
      </div>
      <div className="leading-loose">
        <b>AFFIRM FINANCING</b>
        <br />
        Rates from 0–36% APR. Payment options through Affirm are subject to an eligibility check and
        are provided by these lending partners:{' '}
        <a href="https://affirm.com/lenders" target="_blank" rel="noreferrer nofollow">
          {' '}
          affirm.com/lenders&nbsp;
          <IconExtLink className="size-5" />
        </a>
        . Options depend on your purchase amount, and a down payment may be required. CA residents:
        Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance
        Lenders Law license. For licenses and disclosures, see
        <a href="https://affirm.com/licenses" target="_blank" rel="noreferrer nofollow">
          {' '}
          affirm.com/licenses&nbsp;
          <IconExtLink className="size-5" />
        </a>
        . For example, a $800 purchase could be split into 12 monthly payments of $72.21 at 15% APR
        or 4 interest free payments of $200 every 2 weeks.”
      </div>
    </div>
  )
}

export default AffirmFinancing
